import img_01_0 from './assets/img/PC-R2.jpg';
import img_01_0_s from './assets/img/mobile-R2.jpg';
import img_01_1 from './assets/img/202112/01-1.png';
import img_01_3 from './assets/img/202112/01-3.png';
import img_01_5 from './assets/img/202112/01-5.png';
import img_02_0 from './assets/img/PC-R3.jpg';
import img_02_0_s from './assets/img/mobile-R3.jpg';
import img_02_1 from './assets/img/202112/02-1.jpg';
import img_02_2 from './assets/img/202112/02-2.jpg';
import img_02_3 from './assets/img/202112/02-3.jpg';
import img_02_4 from './assets/img/202112/02-4.jpg';
import img_02_5 from './assets/img/202112/02-5.jpg';
import img_02_6 from './assets/img/202112/02-6.png';
import img_02_7 from './assets/img/202112/02-7.png';
import img_02_8 from './assets/img/202112/02-8.png';
import img_02_9 from './assets/img/202112/02-9.png';
import img_02_10 from './assets/img/202112/02-10.png';
import img_02_11 from './assets/img/202112/02-11.png';
import img_02_12 from './assets/img/202112/02-12.png';
import img_02_13 from './assets/img/202112/02-13.png';
import img_02_14 from './assets/img/202112/02-14.png';
import img_02_15 from './assets/img/202112/02-15.png';
import img_02_16 from './assets/img/202112/02-16.png';
import img_02_17 from './assets/img/202112/02-17.png';
import img_03_0 from './assets/img/202112/PC03-0.png';
import img_03_0_s from './assets/img/202112/03-0.png';
import img_03_1 from './assets/img/202112/03-1.jpg';
import img_03_2 from './assets/img/202112/03-2.jpg';
import img_03_3 from './assets/img/202112/03-3.jpg';
import img_03_4 from './assets/img/202112/03-4.jpg';
import img_03_5 from './assets/img/202112/03-5.png';
import img_03_6 from './assets/img/202112/03-6.png';
import img_03_7 from './assets/img/202112/03-7.png';
import img_03_8 from './assets/img/202112/03-8.png';
import img_03_9 from './assets/img/202112/03-9.png';
import img_03_10 from './assets/img/202112/03-10.png';
import img_03_11 from './assets/img/202112/03-11.png';
import img_03_12 from './assets/img/202112/03-12.png';
import img_04_0 from './assets/img/PC-R6.jpg';
import img_04_0_s from './assets/img/mobile-R6.jpg';
import img_04_1 from './assets/img/202112/04-1.jpg';
import img_04_2 from './assets/img/202112/04-2.jpg';
import img_04_3 from './assets/img/202112/04-3.jpg';
import img_04_4 from './assets/img/202112/04-4.jpg';
import img_04_5 from './assets/img/202112/04-5.jpg';
import img_04_6 from './assets/img/202112/04-6.png';
import img_04_7 from './assets/img/202112/04-7.png';
import img_04_8 from './assets/img/202112/04-8.png';
import img_04_9 from './assets/img/202112/04-9.png';
import img_04_10 from './assets/img/202112/04-10.png';
import img_04_11 from './assets/img/202112/04-11.png';
import img_04_12 from './assets/img/202112/04-12.png';
import img_04_13 from './assets/img/202112/04-13.png';
import img_04_14 from './assets/img/202112/04-14.gif';
import img_04_15 from './assets/img/202112/04-15.png';
import img_04_16 from './assets/img/202112/04-16.png';
import img_04_17 from './assets/img/PC-R4.jpg';
import img_05_0 from './assets/img/202112/PC05-0.png';
import img_05_0_s from './assets/img/202112/05-0.png';
import img_05_1 from './assets/img/202112/05-1.png';
import img_05_3 from './assets/img/202112/05-3.png';
import img_05_5 from './assets/img/202112/05-5.png';
import img_06_0 from './assets/img/202112/PC06-0.png';
import img_06_0_s from './assets/img/202112/06-0.png';
import img_06_1 from './assets/img/202112/06-1.jpg';
import img_06_2 from './assets/img/202112/06-2.jpg';
import img_06_3 from './assets/img/202112/06-3.jpg';
import img_06_4 from './assets/img/202112/06-4.jpg';
import img_06_5 from './assets/img/202112/06-5.jpg';
import img_06_6 from './assets/img/202112/06-6.png';
import img_06_7 from './assets/img/202112/06-7.png';
import img_06_8 from './assets/img/202112/06-8.png';
import img_06_9 from './assets/img/202112/06-9.png';
import img_06_10 from './assets/img/202112/06-10.png';
import img_06_11 from './assets/img/202112/06-11.png';
import img_06_12 from './assets/img/202112/06-12.png';
import img_06_13 from './assets/img/202112/06-13.png';
import img_06_14 from './assets/img/202112/06-14.png';
import img_06_15 from './assets/img/202112/06-15.png';
import img_06_16 from './assets/img/202112/06-16.png';
import img_07_0 from './assets/img/202112/PC07-0.png';
import img_07_0_s from './assets/img/202112/07-0.png';
import img_07_1 from './assets/img/202112/07-1.jpg';
import img_07_2 from './assets/img/202112/07-2.jpg';
import img_07_3 from './assets/img/202112/07-3.jpg';
import img_07_4 from './assets/img/202112/07-4.jpg';
import img_07_5 from './assets/img/202112/07-5.png';
import img_07_6 from './assets/img/202112/07-6.png';
import img_07_7 from './assets/img/202112/07-7.png';
import img_07_8 from './assets/img/202112/07-8.png';
import img_07_9 from './assets/img/202112/07-9.png';
import img_07_10 from './assets/img/PC-R5.jpg';
import img_07_11 from './assets/img/202112/07-11.png';
import img_07_12 from './assets/img/202112/07-12.png';
import img_07_13 from './assets/img/202112/07-13.png';
import img_07_14 from './assets/img/202112/07-14.png';
import img_08_0 from './assets/img/202112/PC08-0.png';
import img_08_0_s from './assets/img/202112/08-0.png';
import img_08_1 from './assets/img/202112/08-1.jpg';
import img_08_2 from './assets/img/202112/08-2.jpg';
import img_08_3 from './assets/img/202112/08-3.jpg';
import img_08_4 from './assets/img/202112/08-4.jpg';
import img_08_5 from './assets/img/202112/08-5.jpg';
import img_08_6 from './assets/img/202112/08-6.png';
import img_08_7 from './assets/img/202112/08-7.png';
import img_08_8 from './assets/img/202112/08-8.png';
import img_08_9 from './assets/img/202112/08-9.png';
import img_08_10 from './assets/img/202112/08-10.png';
import img_08_11 from './assets/img/202112/08-11.png';
import img_08_12 from './assets/img/202112/08-12.png';
import img_08_13 from './assets/img/202112/08-13.png';
import img_08_14 from './assets/img/202112/08-14.gif';
import img_08_15 from './assets/img/202112/08-15.png';

export const menu = [
  {
    text: "關於Britax Römer",
    to: '/about'
  },
  {
    text: "汽車座椅",
    to: '/list/seat'
  },
  {
    text: "嬰兒推車",
    to: '/list/car'
  },
]

export const footerMenu = [
  {
    title: "product",
    links: [
      {
        text: "汽車安全座椅",
        to: "/list/seat"
      },
      {
        text: "嬰兒推車",
        to: "/list/car"
      }
    ]
  },
  {
    title: "about britax",
    links: [
      {
        text: "關於Britax Römer",
        to: "/about"
      },
      {
        text: "來自德國原裝進口",
        to: "/detail"
      },
      {
        text: "獲獎紀錄",
        to: "/award-winning"
      }
    ]
  },
  {
    title: "product",
    links: [
      {
        text: "聯絡我們",
        to: "/contact-us"
      },
    ]
  },
  {
    title: "legal",
    links: [
      {
        text: "隱私權政策及法律聲明",
        to: "/privacy-policy"
      },
    ]
  },
]


export const storeData = [
  {
    title: '北區',
    store: [
      {
        name: '奶娃的店 南京店',
        address: '台北市松山區南京東路五段64號B1',
        tel: '02-2760-2111'
      },
      {
        name: '甜蜜家族 八德旗艦店',
        address: '台北市松山區八德路二段447號',
        tel: '02-2740-6863'
      },
      {
        name: '曖麗嬰童用品',
        address: '台北市中正區南昌路一段163號',
        tel: '02-2394-6681'
      },
      {
        name: '奶娃的店 中和店',
        address: '新北市中和區中山路二段269號B1',
        tel: '02-2242-7877'
      },
      {
        name: '可愛婦嬰用品 羅東店',
        address: '宜蘭縣羅東鎮成功街115號',
        tel: '03-953-0946'
      },
      {
        name: '俏媽咪親子生活館 桃園館',
        address: '桃園市桃園區幸福路77-1號',
        tel: '03-326-1955'
      }
    ]
  },
  {
    title: '中區',
    store: [
      {
        name: '俏媽咪親子生活館',
        address: '新竹縣竹北市莊敬南路100號',
        tel: '03-550-5158'
      },
      {
        name: '愛吾兒婦嬰用品百貨',
        address: '台中市西屯區環中路二段1288-1號',
        tel: '05-2452-5736'
      }
    ]
  },
  {
    title: '南區',
    store: [
      {
        name: '大亨堡婦幼生活館',
        address: '嘉義縣水上鄉中山路二段930號',
        tel: '05-268-6779'
      },
      {
        name: '名媛婦幼用品專賣店',
        address: '嘉義市西區友愛路410號',
        tel: '05-291-0481'
      },
      {
        name: '康寶婦嬰健康生活館',
        address: '台南市麻豆區中正路105號',
        tel: '06-571-3888'
      },
      {
        name: '準媽媽婦嬰用品 金華二店',
        address: '台南市中西區金華路四段74號',
        tel: '06-221-9886'
      },
      {
        name: '愛兒麗婦幼用品連鎖百貨',
        address: '高雄市鼓山區華夏路189號',
        tel: '07-552-6387'
      },
      {
        name: '頑皮寶貝婦嬰用品',
        address: '高雄市左營區明誠二路517號',
        tel: '07-557-8388'
      },
      {
        name: '娃娃世界 鳳山店',
        address: '高雄市鳳山區青年路二段309號',
        tel: '07-777-7827'
      },
      {
        name: '屏東寶貝世界',
        address: '屏東市濟南街12-7號',
        tel: '08-733-3139'
      }
    ]
  }
]

export const productList = {
  seat: {
    img: img_01_0_s,
    lgImg: img_01_0,
    title: '汽車安全座椅',
    products: [
      {
        img: img_01_1,
        title: 'DUALFIX i-SIZE',
        to: 'dualfix'
      },
      {
        img: img_01_3,
        title: 'ADVANSAFIX i-SIZE',
        to: 'advansafix'
      },
      {
        img: img_01_5,
        title: 'KIDFIX i-SIZE',
        to: 'kidfix'
      },
    ]
  },
  car: {
    img: img_05_0_s,
    lgImg: img_05_0,
    title: '嬰兒推車',
    products: [
      {
        img: img_05_1,
        title: 'B-Agile M',
        to: 'agileM'
      },
      {
        img: img_05_3,
        title: 'Gravity II',
        to: 'gravityII'
      },
      {
        img: img_05_5,
        title: 'B-Smile III',
        to: 'smileIII'
      },
    ]
  }
}

export const products = {
  seat: {
    dualfix: {
      img: {
        sm: img_02_0_s,
        lg: img_02_0,
      },
      name: 'DUALFIX i-SIZE',
      swatch: [
        img_02_6,
        img_02_7,
        img_02_8,
        img_02_9,
        img_02_10
      ],
      specification: [
        '適合身高：40-105 cm',
        '適合年齡：出生至約4歲',
      ],
      feature: {
        title: '讓每一次旅行都變得特別',
        content: '從出生到 4 歲，DUALFIX i-SIZE 是您孩子最安心的選擇！時尚、現代的設計，以及我們數十年在兒童汽車安全方面的專業與研究，讓您在任何旅程中都高枕無憂。',
      },
      pictures: [
        img_02_1,
        img_02_2,
        img_02_3,
        img_02_4,
        img_02_5
      ],
      desc: [
        {
          title: '360度旋轉設計',
          content: '360度旋轉的設計，讓爸爸媽媽輕鬆幫助寶寶上下車，不會在空間有限的車子內忙得滿頭大汗！具有12段可調整臥躺的姿勢，滿足您的寶寶旅途中不同的需求。兼具安全性和舒適性，是您旅途中的完美的伴侶！',
          img: img_02_11
        },
        {
          title: '獨家專利SICT降低側撞',
          content: '隱藏在主體內的環狀鋼樑，遭遇側向撞擊時，可以有效分散衝擊力道，確實保護寶寶的頭、頸、肩以降低受傷機率。汽座的軟塑膠本體，就像蠶寶寶的繭一樣，具備吸震、緩衝的能力，就像爸媽把心愛的孩子緊緊抱在懷裡。',
          img: img_02_12,
        },
        {
          title: '前支撐腳與緩衝裝置',
          content: 'Britax Römer Dualfix i-SIZE最著名的，就是前支撐腳和緩衝裝置的設計。不但可以減少碰撞時的位移，更能夠減輕撞擊力道，避免座椅拋飛而產生危險。多段調整的前支撐腳可依照顏色指示判斷是否正確安裝。緩衝反饋裝置也可調整至更貼合您的後座。',
          img: img_02_13,
        },
        {
          title: 'Pivot Link的減少位移機制',
          content: '不同於一般的Isofix，搭載Pivot Link在發生強烈撞擊時，能發揮獨家專利的緩衝機制，帶著寶寶溫柔地「潛入」汽座安全範圍中，避免極大力道造成的位移，傷害寶寶的安全。',
          img: img_02_14,
        },
        {
          title: 'V型包覆頭枕與氯丁橡膠肩墊',
          content: 'Britax Römer Dualfix i-SIZE的「V型包覆頭枕」，能穩固頭部的位移，像是枕著爸媽的臂彎一般的舒適。滑順服貼的五點式安全帶，不但可以穩定地把寶寶固定在座椅之中；「氯丁橡膠肩墊」可以大幅減輕寶寶肩、頸的壓力，讓旅途一路順暢！',
          img: img_02_15,
        },
        {
          title: '寬敞的反向乘坐空間',
          content: '可單手調整頭枕位置，配合多段調整的座位角度，讓越長越大的寶寶，可以有更多的腿部空間，提供最舒適而安全的反向乘坐體驗。\n柔軟的新生兒墊，提供充分的包覆與腰部支撐，讓寶寶一出生就可以在自己的寶座裡坐得舒舒服服！',
          img: img_02_16,
        },
        {
          title: '可單手調整\n前後共12段傾斜角度',
          content: '對於不斷長大的寶寶來說，調整出更多的空間可以讓孩子更舒適！Britax Römer Dualfix i-SIZE擁有前後共12段的傾角設定，單手就可以輕鬆定位，讓寶寶可以更輕易客製化找到屬於自己的舒適空間！對應所有的ISOFIX車款，讓您輕鬆安裝，寶寶輕鬆乘坐！',
          img: img_02_17,
        },
      ],
      videoYT: 'DuTf5n16H6I'
    },
    advansafix: {
      img: {
        sm: img_03_0_s,
        lg: img_03_0,
      },
      name: 'ADVANSAFIX i-SIZE',
      swatch: [
        img_03_5, 
        img_03_6,
      ],
      specification: [
        '適合身高：76-150 cm',
        '適合年齡：2至12歲',
      ],
      feature: {
        title: '為成長中的孩子提供卓越的安全性',
        content: '孩子從 15 個月到 12 歲都能舒適乘坐。符合最新 i-Size 標準，並配有 5 點式安全帶，可延長使用時間，最高可達 21 公斤。XP-PAD和Secure Guard的成熟技術，可提供對頸、胸、腹部的嚴密防護，而 SICT 可保護您的孩子免受側面碰撞。',
      },
      pictures: [
        img_03_1,
        img_03_2,
        img_03_3,
        img_03_4,
      ],
      desc: [
        {
          title: '柔軟的性能胸墊',
          content: '在發生碰撞時可有效減少孩子的移動，並為 5 點式安全帶增加更大的舒適度。此外，它們可以輕鬆取下進行清洗，而不干擾安全帶的使用。',
          img: img_03_7,
        },
        {
          title: 'Secure Guard',
          content: '在四歲(18kg以上)改用車用三點式安全帶後，隨貨附贈的Secure Guard，在原本的三點式安全帶之中增加了第四點，實測可減少腹部35%的撞擊壓力。',
          img: img_03_8,
        },
        {
          title: '可調整V形頭枕',
          content: 'V 形頭枕專為控制您孩子的頭部運動而設計，可確保在發生側面碰撞時提供最佳保護，同時提供極致舒適感。此外，頭枕和安全帶可以從座椅前部輕鬆調節，以適應您孩子的身高。',
          img: img_03_9,
        },
        {
          title: 'XP-PAD',
          content: '在四歲(18  kg以上)改用車用三點式安全帶後隨貨附贈的XP-PAD，是新開發的專利減震泡沫墊，讓下巴、頸部、胸部減少30%的撞擊力道。',
          img: img_03_10,
        },
        {
          title: 'Pivot Link的減少位移機制',
          content: '不同於一般的Isofix，搭載Pivot Link在發生強烈撞擊時，能發揮獨家專利的緩衝機制，帶著寶寶溫柔地「潛入」汽座安全範圍中，避免極大力道造成的位移，傷害寶寶的安全。',
          img: img_03_11,
        },
        {
          title: '獨家專利SICT降低側撞',
          content: '當遭遇側撞撞擊時，可有效分散衝擊力道，確實保護寶寶的頭、頸、肩以降低受傷機率。只需將靠近車門的SICT機制旋轉開啟。',
          img: img_03_12,
        },
      ],
      videoYT: 'bpORMXIt1dc'
    },
    kidfix: {
      img: {
        sm: img_04_0_s,
        lg: img_04_0,
      },
      name: 'KIDFIX i-SIZE',
      swatch: [
        img_04_6,
        img_04_7,
        img_04_8,
        img_04_9,
        img_04_10
      ],
      specification: [
        '適合身高：100-150cm',
        '適合年齡：3.5至12歲',
      ],
      feature: {
        title: '極致保護孩子成長',
        content: '新一代 KIDFIX i-SIZE 是與汽車製造商合作開發，希望能提供極致的汽車旅行安全。它配備了XP-PAD和SecureGuard技術，多次實驗驗證可有效降低胸壓與腹壓，更有 SICT 保護您的孩子免受側面碰撞。精心佈置的通風孔有助於空氣流通，讓您的孩子長時間乘坐也能保持舒適。',
      },
      pictures: [
        img_04_1,
        img_04_2,
        img_04_3,
        img_04_4,
        img_04_5
      ],
      desc: [
        {
          title: '高椅背安全座椅',
          content: '提供頭部到臀部的保護，V型頭枕穩定頭頸，兼具舒適與安全。',
          img: img_04_11,
        },
        {
          title: '符合人體工學的座椅角度',
          content: '是britax römer運用AI高科技數千次模擬出來的最佳設計',
          img: img_04_12,
        },
        {
          title: 'Secure Guard',
          content: '在原本的三點式安全帶之中增加了第四點，實測可以減少腹部35%的撞擊壓力。',
          img: img_04_13,
        },
        {
          title: '獨家專利SICT降低側撞',
          content: 'britax römer最引以為傲的側撞防護專利SICT，能有效吸收超過40%來自側向撞擊的力道，確實達到對孩子的有效保護。',
          img: img_04_14,
        },
        {
          title: 'XP-PAD',
          content: '新開發的專利減震泡沫墊，讓下巴、頸部、胸部減少了30%的撞擊力道。',
          img: img_04_15,
        },
        {
          title: '通風座椅',
          content: '精心佈置的通風孔，能夠有效改善空氣流通，提供額外的舒適感。坐在座椅中，您的小乘客在長途汽車旅行和一天中的任何時間都會感到非常舒適。',
          img: img_04_16,
        },
        {
          title: '',
          content: '在夜晚一片漆黑的道路上，這是最容易辨識的顏色！Britax Römer KIDFIX i-Size成長型安全汽座，閃爍著耀眼的光芒，守護著每一位孩子的夢想！',
          img: img_04_17
        }
      ],
      videoYT: 'pkeIW7ywwzg'
    }
  },
  car: {
    agileM: {
      img: {
        sm: img_06_0_s,
        lg: img_06_0,
      },
      name: 'B-Agile M',
      swatch: [
        img_06_6,
        img_06_7,
        img_06_8,
        img_06_9
      ],
      specification: [
        '適合體重：0-22kg',
        '適合年齡：出生至4歲',
      ],
      feature: {
        content: '2020全新升級B-AGILE M，具現代化設計高質感皮革扶手以及抗UPF50全罩式頂篷，輪圈車架新增折射反光條，在夜間推行增加安全性。機械式三段靠背調整，收車後可自動站立，也可接提籃成最完美的TRAVEL SYSTEM。',
      },
      pictures: [
        img_06_2,
        img_06_3,
        img_06_4,
        img_06_5
      ],
      desc: [
        {
          title: '三段平躺',
          content: '可調節的靠背和小腿支撐提供了一系列的姿勢，從新生兒平躺到休息的幼兒都可設定座椅位置。無論是睡得香還是坐得穩，三段式設計都可以輕鬆讓他們感到舒適。',
          img: img_06_10,
        },
        {
          title: 'UPF50頂篷',
          content: '頂篷除了可以有效遮陽外還有UPF 50+ 防曬功能。頂篷上還設有觀景窗，再熱的天都不用擔心。',
          img: img_06_11,
        },
        {
          title: '特殊元素設計',
          content: '產品質感與安全等級完整提升！「五點式安全背帶」新型滑動式設計，適合所有年齡段的完美肩帶位置；「無穿刺前後輪胎」避免爆胎危險；「蜂窩狀輪子設計」外圈rubber skin材質，減壓避震，耐磨度大增；「輪圈折射反光條」夜晚安全度大升級！',
          img: img_06_12,
        },
        {
          title: '前輪定向系統、\n可360°旋轉避震系統',
          content: '兩大系統確保行駛穩定度，顛簸地面也能確保平穩。「前輪定向系統」360度旋轉，向上提拉即固定；「懸吊式避震系統」獨立工作的前後懸架，完美分攤重量，保持推車平衡。',
          img: img_06_13,
        },
        {
          title: '單手收折好方便',
          content: '只要幾秒鐘，體驗單手收車的便利！一手提車一手可自由抱孩子，折疊後更可獨立站立，外出搬運，存放皆方便！',
          img: img_06_14,
        },
        {
          title: '寬敞大置物空間',
          content: '孩子需求一次帶齊，完美應付外出大小事！置物籃乘載重量可達4kg。',
          img: img_06_15,
        },
        {
          title: '可與提籃結合成\nTravel System',
          content: '正反雙向設計，親子交流零距離，是推車也是提籃汽座，完整的旅行系統就靠它！',
          img: img_06_16,
        },
      ],
      videoYT: 'BOOH1WpW-jQ'
    },
    gravityII: {
      img: {
        sm: img_07_0_s,
        lg: img_07_0,
      },
      name: 'Gravity II',
      swatch: [
        img_07_5,
        img_07_6,
        img_07_7
      ],
      specification: [
        '適合體重：0-22kg',
        '適合年齡：出生至4歲',
      ],
      feature: {
        content: '通過重力自動折疊技術，折疊和展開操作輕鬆又省力！折疊後自動站立，機械式靠背調整，輕鬆帶著走！新型 滑動式肩帶，適合所有年齡段的完美肩帶位置。',
      },
      pictures: [
        img_07_1,
        img_07_2,
        img_07_3,
        img_07_4,
      ],
      desc: [
        {
          title: 'Britax第一台可自動收車',
          content: '單手自動折疊技術，折疊和展開操作極為輕鬆，折疊後可獨立站立，便於存放。',
          img: img_07_8,
        },
        {
          title: '全罩式頂篷，\n高質感仿皮革扶手',
          content: '全罩式頂篷，抗UPF 50+，含觀景窗。新型滑動式背帶，適合所有年齡段的完美肩帶位置，扶手採高質感仿皮革設計。',
          img: img_07_9,
        },
        {
          title: '人字型支架',
          content: '人字型支架設計，三重加固不易倒',
          img: img_07_10,
        },
        {
          title: '橡膠皮輪胎',
          content: '橡膠皮輪胎皮防水防滑耐磨，四輪彈簧避震，各種路面輕鬆推行!',
          img: img_07_11,
        },
        {
          title: '網狀觀景窗',
          content: '側邊 椅背後方網狀觀景窗，增加空氣流通透氣。',
          img: img_07_12,
        },
        {
          title: '滑動式肩帶',
          content: '單手可上下調整的安全帶，無須拆卸。',
          img: img_07_13,
        },
        {
          title: '可接提籃一車兩用',
          content: '可接提籃的輕便推車(提籃轉接器需另購)',
          img: img_07_14,
        },
      ],
      videoYT: 'chdYmR6Tk9c'
    },
    smileIII: {
      img: {
        sm: img_08_0_s,
        lg: img_08_0,
      },
      name: 'B-Smile III',
      swatch: [
        img_08_6,
        img_08_7,
      ],
      specification: [
        '適合體重：0-22kg',
        '適合年齡：出生至4歲',
      ],
      feature: {
        title: '',
        content: '',
      },
      pictures: [
        img_08_1,
        img_08_2,
        img_08_3,
        img_08_4,
        img_08_5
      ],
      desc: [
        {
          title: '座椅正反雙向調整',
          content: '無論寶寶想要與您保持互動或是探索世界，借助快速簡便的可逆座椅裝置，在任何旅程中都能讓寶寶隨心所欲。',
          img: img_08_8,
        },
        {
          title: '機械式椅背3段調整',
          content: '得益於48釐米的超長的靠背，寶寶有足夠的空間成長並在嬰兒車上享受每趟旅程。',
          img: img_08_9,
        },
        {
          title: '全罩式頂篷',
          content: '無論在什麼季節，SMILE III都是正確的選擇。夏天，UPF50+的超大遮陽蓬保護孩子，讓孩子在明媚的陽光下散步成為一種安全的享受。',
          img: img_08_10,
        },
        {
          title: '充氣輪胎',
          content: '得益於大型充氣輪，這款嬰兒車可以輕鬆在任何地形上操作，無論是鵝卵石還是鄉間小路，同時為您的寶寶提供平穩的乘車體驗。',
          img: img_08_11,
        },
        {
          title: '超大置物空間',
          content: '無論是要進行長途一日遊或是附近逛逛，方便取用的儲物籃可裝下7公斤您所需要的一切用品。',
          img: img_08_12,
        },
        {
          title: '3M反光條',
          content: '為了在光線不足的情況下確保安全，嬰兒車的四周(前蓋、籃子周圍和輪子)都備了3M反光條。',
          img: img_08_13,
        },
        {
          title: '可調整手把',
          content: '高質感皮革扶手，可調節高度80-113cm。',
          img: img_08_14,
        },
        {
          title: '歐規一踩雙煞',
          content: '可以鎖定兩個後輪，並有一個指示燈顯示你是否鎖定了剎車。為您的小寶貝提供額外的安全保障！',
          img: img_08_15,
        },
      ],
      videoYT: '-bNVMEZ8Vd4'
    },
  }
}