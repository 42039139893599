import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

const BurgerStyled = styled.button`
  --timing-fun-1: cubic-bezier(.55, .055, .675, .19);
  --timing-fun-2: cubic-bezier(.33333, .66667, .66667, 1);
  --timing-fun-3: cubic-bezier(.215, .61, .355, 1);
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
  //background-color: var(--primary);
  border-radius: 0;
  padding: 0;

  &:focus {
    outline-width: 0;
  }

  .burger-box {
    width: 3rem;
    height: 2.4rem;
    position: relative;
  }
  
  .burger-box-inner {
    --barColor: var(--black);
    --barHeight: .3rem;
    width: 100%;
    height: var(--barHeight);
    border-radius: 0;
    background-color: var(--barColor);
    position: absolute;
    bottom: 0;
    transition: transform .13s .13s var(--timing-fun-2);
    
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: var(--barHeight);
      border-radius: 0;
      background-color: var(--barColor);
      position: absolute;
      top: -1rem;
      transition: top .2s .2s var(--timing-fun-2),
      transform .13s .13s var(--timing-fun-2);
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: var(--barHeight);
      border-radius: 0;
      background-color: var(--barColor);
      position: absolute;
      top: -2rem;
      transition: top .2s .2s var(--timing-fun-2),
      transform .13s .13s var(--timing-fun-2);
    }
  }

  &.active {
    //background-color: transparent;
    .burger-box-inner {
      --barColor: var(--black);
      transform: translate3d(0, -1rem, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: var(--timing-fun-3);

      &::before {
        top: 0;
        transition: top .1s .16s var(--timing-fun-2),
        transform .13s .25s var(--timing-fun-3);
        transform: rotate(-90deg);
      }

      &::after {
        top: 0;
        transition: top .2s var(--timing-fun-2),
        opacity .1s .22s linear;
        opacity: 0;
      }
    }
  }
`;

const BurgerButton = ({onClick, isShow = false, className, ...props}) => {
  return (
    <BurgerStyled {...props} onClick={onClick} className={clsx(className, {active: isShow})}>
      <div className="burger-box">
        <div className="burger-box-inner"/>
      </div>
    </BurgerStyled>
  );
}

export default BurgerButton;
