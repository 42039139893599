import React, {useState} from 'react'
import styled from 'styled-components';
import Img from './Img'

const CarouselStyle = styled.div`
  
`;
const DisplayStyle = styled.div`
  
`;

const ListStyle = styled.ul`
  list-style:none;
  padding-left: 0;
  display: flex;
  overflow: hidden;
  padding: 0 1rem;
  > li {
      flex: 0 0 20%;
      &:not(&.current) {
          opacity: .5;
      }
  }
`;

const Carousel = ({pictures}) => {
    const [current, setCurrent] = useState(0);
    return (
      <CarouselStyle>
        <DisplayStyle>
            <Img key={pictures[current]} src={pictures[current]}/>
        </DisplayStyle>
        <ListStyle>
          {pictures.map((pic, i) => (
            <li key={i} className={current===i && 'current'} onClick={() => {
                setCurrent(i)
            }}>
              <Img src={pic} auto/>
            </li>
          ))}
        </ListStyle>
      </CarouselStyle>
    );
}

export default Carousel
