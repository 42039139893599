import React from 'react';
import Layout from '../components/Layout';
import Card from '../components/Card';
import {BaseButton} from '../components/Button';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CardGridStyle} from '../styles/Grids';
import {breakPoint} from '../styles/var';
import Img from '../components/Img';

import img_1_2 from '../assets/img/PC01-2.png';
import img_1_2_s from '../assets/img/01-2.png';
import img_1_3 from '../assets/img/PC01-3.png';
import img_1_3_s from '../assets/img/01-3.png';
import img_search from '../assets/img/PC01-4.png';

const KVStyle = styled.div`
  position: relative;
  z-index: var(--level1);
  margin-bottom: 3rem;
  > .video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  }
  > .title {
    font-size: 4.8rem;
    white-space: nowrap;
    color: var(--white);
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 0 0 8px rgba(0 0 0 / 80%);
    text-align: center;
  }
  .link {
    position: absolute;
    top: calc(100% + 30px);
    right: 0;
  }
  .link-img {
    width: auto;
  }
  @media (max-width: ${breakPoint.max}) {
    > .title {
      font-size: 2.4rem;
    }
    .link {
      position: absolute;
      top: calc(100% + 20px);
      right: 0;
    }
    .link-img {
      max-width: 5rem;
    }
  }
`;

const ContentStyle = styled(Layout)`
  .page-title {
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;
  }
  @media (max-width: ${breakPoint.max}) {
    .page-title {
      font-size: 1.6rem;
      padding-right: 5rem;
      padding-left: 5rem;
    }
  }
`;

const GridStyle = styled(CardGridStyle)`
  margin-top: 3rem;
`;

const Home = (props) => {
  return (
    <ContentStyle>
      <KVStyle>
          <div className="video">
              <iframe src="https://www.youtube.com/embed/3uY_hJ4wo1c?modestbranding=1&autoplay=1&mute=1&controls=0&rel=0&loop=1&disablekb=1&fs=0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen>
              </iframe>
          </div>
        <h1 className="title">皇室尊享，Britax安全隨行</h1>
        <Link className='link' to="/store">
          <Img src={img_search} wrapProps={{className: 'link-img'}} auto/>
        </Link>
      </KVStyle>
      <h3 className="page-title">皇室專用品牌，致力於汽車安全的技術領域，相信多年的專業技術與根基，才能成就永恆的卓越創新標準。</h3>
      <GridStyle>
        <Card imgUrl={img_1_2} smallImgUrl={img_1_2_s}>
          <h2>汽車安全座椅</h2>
          <p>嚴格把關，Britax無疑是最經得起考驗的安全首選</p>
          <BaseButton as={Link} to="/list/seat" className="btn">探索</BaseButton>
        </Card>
        <Card imgUrl={img_1_3} smallImgUrl={img_1_3_s}>
          <h2>嬰兒推車</h2>
          <p>優雅時尚，Britax品質與質感兼具絕佳外出夥伴</p>
          <BaseButton as={Link} to="/list/car" className="btn">探索</BaseButton>
        </Card>

      </GridStyle>
    </ContentStyle>
  )
};

export default Home;
