import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {breakPoint} from '../styles/var';

const LinkGridStyled = styled.div`
  .title {
    padding: .5rem 1rem;
    text-transform: uppercase;
    &::after {
      content: "+";
      margin-left: 1rem;
    }
  }
  .link-title {
    cursor: pointer;
  }
  .link {
    display: block;
    padding: .5rem 1rem;
  }
  @media (min-width: ${breakPoint.min}) {
    .link-title {
      display: none;
    }
  }
  @media (max-width: ${breakPoint.max}) {
    .title:not(.link-title) {
      display: none;
    }
    .links {
      &:not(.show) {
        display: none;
      }
    }
  }
`;


const LinkGroup = ({title, links, ...props}) => {
  const [isShow, setIsShow] = useState(false);
  const handleClick = () => {
    setIsShow(!isShow);
  }
  return (
    <LinkGridStyled {...props}>
      <h3 className="title link-title" onClick={handleClick}>{title}</h3>
      <h3 className="title">{title}</h3>
      <LinkGridStyled className={`links ${isShow && 'show animate__animated animate__fadeInRight animate__faster'}`}>
        {
          links.map(({to, text}, i) => (
            <Link key={i} className="link" to={to}>{text}</Link>
          ))
        }
      </LinkGridStyled>
    </LinkGridStyled>
  )
};

export default LinkGroup;
