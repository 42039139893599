import React from 'react';
import styled from 'styled-components';


const AutoImgWrap = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${({rounded}) => rounded ? '50%' : ''};
  overflow: hidden;
  font-size: 0;
  & > img {
    max-width: 100%;
    height: auto;
     vertical-align: middle;
  }
`

const FixedImgWrap = styled(AutoImgWrap)`
  font-size: 0;
  & > div {
    height: 0;
    padding-bottom: 100%;
  }
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: ${({objectFit}) => objectFit || 'cover'};
    object-position: center center;
  }
`

const Img = ({refs, objectFit, auto, alt = "", rounded, wrapProps, children, ...props}) => {
  if (Boolean(auto)) {
    return (
      <AutoImgWrap {...wrapProps} rounded={rounded}>
        {children}
        <img ref={refs} {...props} alt={alt}/>
      </AutoImgWrap>
    )
  }
  return (
    <FixedImgWrap {...wrapProps} objectFit={objectFit} rounded={rounded}>
      <div/>
      <img ref={refs} {...props} alt={alt}/>
    </FixedImgWrap>
  )
}

export default Img;
