import React from 'react'
import styled from 'styled-components';
import Layout from '../components/Layout'
import { products } from '../data';
import PageHead from '../templates/PageHead';
import { Container } from '../styles/Grids';
import Card from '../components/Card';
import Carousel from '../components/Carousel';
import Img from '../components/Img';
import { breakPoint } from '../styles/var';

const MainStyles = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  @media (min-width: ${breakPoint.min}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
  }
`;
const ColStyles = styled.div`
  .name {
      margin-bottom: 1rem;
      margin-top: 1rem;
  }
  .btn {
      display: block;
      width: 48%;
      margin-left: auto;
      margin-right: auto;
  }
  @media (min-width: ${breakPoint.min}) {
    font-size: 2rem;
  }
  @media (max-width: ${breakPoint.max}) {
    margin-bottom: 1.5rem;
  }
`;

const SwatchStyles = styled.ul`
  display: flex;
  
  li {
    width: 3.6rem;
    + li {
    margin-left: 1rem;
  }
  }
  @media (max-width: ${breakPoint.max}) {
    justify-content: center;
  }
`;

const SpecificationStyles = styled.div`
  width: 100%;
  border: 1px solid #606B75;
  padding: 2rem;
  margin-bottom: 1.5rem;
  > :last-child {
    margin-bottom: 0;
  }
  @media (min-width: ${breakPoint.min}) {
    font-size: 1.6rem;
    margin-bottom: 2.5rem;
  }
  @media (max-width: ${breakPoint.max}) {
    .h3 {
      font-size: 2.4rem;
      margin-bottom: 2.5rem;
    }
    .sf {
      font-size: 1.4rem;
    }
    & + h2 {
      font-size: 2.4rem;
    }
  }
`;

const CardGridStyle = styled.div`
  .card {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
    > * {
      grid-row-start: 1;
    }
    &:nth-child(even) {
      .img {
        grid-column-start: 1;
      }
    }
    &:nth-child(odd) {
      .img {
        grid-column-start: 2;
      }
    }
    .content {
      padding: 0;
      > .title {
        white-space: pre-line;
      }
    }

    @media (min-width: ${breakPoint.min}) {
      margin-bottom: 4rem;
      .content {
        font-size: 2rem;
        max-width: none;
        width: 80%;
      }
    }
    @media (max-width: ${breakPoint.max}) {
      .content {
        > .title {
          font-size: 2.4rem;
        }
      }
    }
  }
`;

const VideoStyles = styled.video`
  width: 100%;
  margin-bottom: 3rem;
  @media (min-width: ${breakPoint.min}) {
    margin-bottom: 5rem;
  }
`;

const VideoYTStyle = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: 3rem;
    @media (min-width: ${breakPoint.min}) {
      margin-bottom: 5rem;
    }
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
`;
const Product = ({match, ...props}) => {
    const {target, productName} = match.params;
    if (!products[target] || !products[target][productName]) return false;
    const {name, img, pictures, specification, swatch, feature, desc, video, videoYT} = products[target][productName];

    return (
      <Layout {...props}>
        <PageHead img={img} />
        <Container>
          <MainStyles>
            <ColStyles>
              <Carousel pictures={pictures} />
            </ColStyles>
            <ColStyles>
              <SpecificationStyles>
                <h1 className="h3">{name}</h1>
                <SwatchStyles className="list-unstyled">
                  {swatch.map((img) => (
                    <li key={img.sm}>
                      <Img src={img} auto />
                    </li>
                  ))}
                </SwatchStyles>
                <ul className="list-unstyled sf">
                  {specification.map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
                </ul>
              </SpecificationStyles>
              {feature.title && <h2>{feature.title}</h2>}
              <p>{feature.content}</p>
            </ColStyles>
          </MainStyles>
          <CardGridStyle>
            {desc.map((d) => (
              <Card key={d.img} imgUrl={d.img}>
                <h2 className="title">{d.title}</h2>
                <p>{d.content}</p>
              </Card>
            ))}
          </CardGridStyle>

            {videoYT && (
                <VideoYTStyle className="video">
                <iframe src={`https://www.youtube.com/embed/${videoYT}?modestbranding=1&mute=1&rel=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                </iframe>
                </VideoYTStyle>
                )}
        </Container>
      </Layout>
    );
}

export default Product
