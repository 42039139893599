import React from 'react';
import styled from 'styled-components';
import OuterLink from './OuterLink';
import withHover from '../HOC/withHover';

const BaseStyle = styled(OuterLink)`
  display: inline-block;
`;
const ButtonStyle = styled(BaseStyle)`
  min-width: 10rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  --bg: var(--primary);
  background-color: var(--bg);
  color: var(--white);
  padding: 0 1.5rem;
  border-radius: 3rem;
  position: relative;
  background-clip: border-box;
  border: 5px solid rgba(255, 255, 255, 0);
  box-shadow: 0 8px 10px -4px rgba(0,0,0,.3);
`;

let NoStyleBtn= ({children, ...props}) => {
  return (
    <BaseStyle {...props}>
      {children}
    </BaseStyle>
  );
};

let BaseBtn = ({children, ...props}) => {
  return (
    <ButtonStyle {...props}>
      {children}
    </ButtonStyle>
  );
};



export const NoStyleButton =  withHover(NoStyleBtn);
export const BaseButton =  withHover(BaseBtn);
