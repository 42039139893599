import React from 'react';
import Layout from '../components/Layout';
import PageHead from '../templates/PageHead';
import Card from '../components/Card';
import {CardGridBaseStyle, CardGridStyle, Container} from '../styles/Grids';
import styled from 'styled-components';
import {BaseButton} from '../components/Button';
import {Link} from 'react-router-dom';

import img_3_1 from '../assets/img/PC03-1.png';
import img_3_1_s from '../assets/img/03-1.png';
import img_3_2 from '../assets/img/PC03-2.png';
import img_3_3 from '../assets/img/PC03-3.png';
import img_3_4 from '../assets/img/PC03-4.png';
import img_3_5 from '../assets/img/PC03-5.png';
import img_3_4_s from '../assets/img/03-4.png';
import img_3_5_s from '../assets/img/03-5.png';

const headImg = {
  lg: img_3_1,
  sm: img_3_1_s
}

const GridStyle = styled(CardGridStyle)`
  border-top: 1px solid var(--font);
  margin-top: 3rem;
  padding-top: 3rem;
`;

const About = (props) => {
  return (
    <Layout>
      <PageHead img={headImg} />
      <Container>
        <h1 className="page-title">關於Britax Römer</h1>
        <CardGridBaseStyle className="grid">
          <Card imgUrl={img_3_2}>
            <h2>更安全、更簡單、適合每個孩子</h2>
            <p>更安全是我們對全世界家庭的承諾；更簡單是讓父母放心盡情享受生活的使命；適合每個孩子是我們努力奮鬥的目標！</p>
          </Card>
          <Card imgUrl={img_3_3}>
            <h2>法規是Britax的最低標準</h2>
            <p>在各國政府對保護兒童裝置法規不斷提升之前，Britax已經不斷地設立安全新標準！</p>
          </Card>
        </CardGridBaseStyle>
      </Container>
      <GridStyle>
        <Card imgUrl={img_3_4} smallImgUrl={img_3_4_s}>
          <h2>100%<br/>
            德國製原裝進口</h2>
          <p>更安全是我們對全世界家庭的承諾；更簡單是讓父母放心盡情享受生活的使命；適合每個孩子是我們努力奮鬥的目標！</p>
          <BaseButton as={Link} to="/detail" className="btn">探索</BaseButton>
        </Card>
        <Card imgUrl={img_3_5} smallImgUrl={img_3_5_s}>
          <h2>獎項與榮譽</h2>
          <p>Britax Römer屢獲各式奬項及殊榮，以表彰一直以來的創新、堅持與努力。</p>
          <BaseButton as={Link} to="/award-winning" className="btn">探索</BaseButton>
        </Card>
      </GridStyle>
    </Layout>
  )
};

export default About;
