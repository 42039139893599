import React, {useState} from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import BurgerButton from './BurgerButton';
import Img from './Img';
import {Container} from '../styles/Grids';
import CusLink from './CusLink';
import {MenuStyles} from '../styles';
import {menu} from '../data';
import {breakPoint, headerBreakPoint} from '../styles/var';

import logo from '../assets/img/PC00-1.png';



const HeaderStyles = styled.header`
  
  width: 100%;
  padding: 1.5rem 2rem;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0,0,0,.4);
  background-color: rgba(255,255,255, .9);
  z-index: var(--level3);
  .menu-btn {
    position: fixed;
  }
  .container {
    display: flex;
    justify-content: center;
  }
  .logo {
    width: auto;
  }
  a {
    color: var(--font);
  }
  @media (min-width: ${headerBreakPoint.min}) {
    .menu-btn {
      display: none;
    }
    .logo {
      margin-right: 3rem;
    }
  }
  @media (max-width: ${headerBreakPoint.max}) {
    .menu-btn {
      top: 1.2rem;
      left: 0;
    }
  }
  @media (max-width: ${breakPoint.smMax}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    .menu-btn {
      top: 1rem;
      left: 0;
    }
    .logo {
      max-width: 9rem;
    }
  }
`
const MainMenuStyles = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${headerBreakPoint.max}) {
    display: none;
    background-color: var(--bg);
    padding-top: 3rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--level1);
    &.show {
      display: block;
    }
    .menu-wrap {
      flex-direction: column;
      flex-wrap: nowrap;
      border-bottom: 1px solid;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .menu-btn {
      left: unset;
      right: 2rem;
    }    
  }
`
const MenuItemStyles = styled.li`
  @media (max-width: ${headerBreakPoint.max}) {
    > a {
      padding: 1rem 0 1rem 3rem;
    }
  }
`;

const Header = () => {
  const [isShow, setIsShow] = useState(false);
  const handleClick = () => {
    setIsShow(prev => !prev);
  }
  const handleClose = () => {
    setIsShow(false);
  }
  return (
    <HeaderStyles>
      <Container className="container">
        <Link to="/">
          <Img src={logo} auto wrapProps={{className: "logo"}}/>
        </Link>
        <MainMenuStyles id="menu" className={clsx('animate__faster', {show: isShow, animate__animated: isShow, animate__fadeInDown: isShow})}>
          <MenuStyles justifyContent="flex-end" className='menu-wrap'>
            {menu.map(({text, to}, i) => (
              <MenuItemStyles key={`${text}${i}`} className="link-item">
                <Link to={to} onClick={handleClose}>{text}</Link>
              </MenuItemStyles>
            ))}
          </MenuStyles>
          <CusLink />
          <BurgerButton className="menu-btn" isShow={true} onClick={handleClick}/>
        </MainMenuStyles>
        <BurgerButton className="menu-btn" isShow={false} onClick={handleClick}/>
      </Container>
    </HeaderStyles>
  )
}

export default Header;
