import React from 'react';
import Layout from '../components/Layout';
import PageHead from '../templates/PageHead';
import Card from '../components/Card';
import {CardGridStyle} from '../styles/Grids';


import img_2_1 from '../assets/img/PC02-1.png';
import img_2_1_s from '../assets/img/02-1.png';
import img_4_2 from '../assets/img/PC04-2.png';
import img_4_2_s from '../assets/img/04-2.png';
import img_4_3 from '../assets/img/PC04-3.png';
import img_4_3_s from '../assets/img/04-3.png';


const headImg = {
  lg: img_2_1,
  sm: img_2_1_s
}

const Detail = (props) => {
  return (
    <Layout>
      <PageHead img={headImg}/>
      <h1 className="page-title">德國原裝進口</h1>
      <CardGridStyle>
        <Card imgUrl={img_4_2} smallImgUrl={img_4_2_s}>
          <h2>在細節處，觸摸百年工藝</h2>
          <p>每一張座椅都是經德國匠師親手組裝而成，我們相信，只有在細節處無與倫比的執念，才是對安全的最大的尊崇。</p>
        </Card>
        <Card imgUrl={img_4_3} smallImgUrl={img_4_3_s}>
          <h2>懂你所需 想你未想</h2>
          <p>Britax Römer懷著父母的心情，不斷思考寶寶需要什麼，為提供給孩子更好的安全保護而努力著。</p>
        </Card>
      </CardGridStyle>
    </Layout>
  )
};

export default Detail;
