import React from 'react';
import Layout from '../components/Layout';
import PageHead from '../templates/PageHead';
import {Container} from '../styles/Grids';
import styled from 'styled-components';

import img_4_1 from '../assets/img/PC-R1.jpg';
import img_4_1_s from '../assets/img/mobile-R1.jpg';
import img_local from '../assets/img/PC02-2.png';
import img_tel from '../assets/img/PC02-3.png';
import {storeData} from '../data';
import {breakPoint} from '../styles/var';


const headImg = {
  lg: img_4_1,
  sm: img_4_1_s
}

const ContentStyle = styled(Layout)`
  .store-wrap {
    max-width: 96rem;
    margin-right: auto;
    margin-left: auto;
  }
`;

const StoreListStyle = styled.div`
  margin-bottom: 6rem;
  > .title {
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--font);
    padding-bottom: 1rem;
    padding-left: 3.6rem;
    &::before {
      content: '';
      font-size: 0;
      width: 3rem;
      height: 3rem;
      background: url(${img_local}) no-repeat center center / contain;
      margin-right: .8rem;
    }
  }
  .store {
    padding-left: 0;
  }
`;

const StoreItemStyle = styled.li`
  display: flex;
  border-bottom: 1px solid rgba(69,107,117, .5);
  > span {
    width: 100%;
    padding: .4rem 4rem;
  }
  .tel {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 2.4rem;
      height: 2.4rem;
      background: url(${img_tel}) no-repeat center center / contain;
      margin-right: .8rem;
    }
  }
  @media (min-width: ${breakPoint.min}) {
    > span {
      padding-top: .8rem;
      padding-bottom: .8rem;
      padding-right: 4rem;
      :first-child {
        padding-left: 4rem;
      }
    }
    .tel {
      width: 80%;
    }
  }
  @media (max-width: ${breakPoint.max}) {
    padding-bottom: .8rem;
    flex-direction: column;
    & + & {
      padding-top: .8rem;
    }
  }
`;

const StoreList = ({title, store}) => {
  return (
    <StoreListStyle>
      <h3 className="title">{title}</h3>
      <ul className="store">
        {
          store.map(({name, address, tel}, i) => (
            <StoreItemStyle key={i}>
              <span>{ name }</span>
              <span>{ address }</span>
              <span className="tel">{ tel }</span>
            </StoreItemStyle>
          ))
        }
      </ul>
    </StoreListStyle>
  )
}

const Store = (props) => {
  return (
    <ContentStyle>
      <PageHead img={headImg} hasLink={false} />
      <Container className="container">
        <h1 className="page-title">經銷門市</h1>
        <div className="store-wrap">
          {
            storeData.map((storeData, i) => (
              <StoreList key={i} {...storeData} />
            ))
          }
        </div>
      </Container>
    </ContentStyle>
  )
};

export default Store;
