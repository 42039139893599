import Home from './pages/Home';
import About from './pages/About';
import AwardWinning from './pages/AwardWinning';
import Detail from './pages/Detail';
import Store from './pages/Store';
import List from './pages/List';
import Product from './pages/Product';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';

const Routes = [
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/award-winning",
    component: AwardWinning,
  },
  {
    path: "/detail",
    component: Detail,
  },
  {
    path: "/store",
    component: Store,
  },
  {
    path: "/list/:target",
    component: List,
    exact: true
  },
  {
    path: "/list/:target/:productName",
    component: Product,
    exact: true
  },
  {
    path: "/contact-us",
    component: ContactUs,
    exact: true
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: true
  },
];

export default Routes;
