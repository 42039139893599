import { createGlobalStyle } from 'styled-components';
import {breakPoint} from './var';

const Typography = createGlobalStyle`
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 500;
    margin: 0;
  }
  a {
    color: var(--black);
    text-decoration: none
  }
  .text-primary {
    color: var(--primary);
  }
  .text-secondary {
    color: var(--secondary)
  }
  .text-tertiary {
    color: var(--tertiary);
  }
  .center {
    text-align: center;
  }
  .page-title {
    text-align: center;
    margin-bottom: 2rem;
  }
  @media (min-width: ${breakPoint.min}) {
    h1, .h1 {
      font-size: 4rem;
    }
    h2, .h2 {
      font-size: 2.8rem;
    }
  }
  @media (max-width: ${breakPoint.max}) {
    .page-title {
      font-size: 2.8rem;
    }
  }
`;

export default Typography;
