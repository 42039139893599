import {createGlobalStyle} from 'styled-components';


const GlobalStyles = createGlobalStyle`
  :root {
    --primary: #D8012C;
    --secondary: #4DD0E1;
    --tertiary: #FCCD28;
    --bg: var(--white); 
    --grey: #E1E1E1;
    --white: white;
    --black: black;
    --font: #606B75;
    --box-shadow: 0.8524px 0.8524px 4.2622px rgba(0, 0, 0, .5);
    --level1: 1000;
    --level2: 1010;
    --level3: 1020;
    --blue: var(--secondary);
    --pink: #FC56E4;
    --yellow: var(--tertiary);
  }

  html {
    font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
    color: var(--font);
    background-color: var(--white);
    font-size: 10px;
  }

  body {
    font-size: 1.6rem;
    line-height: 1.6;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center bottom;

    & * {
      box-sizing: inherit;

      &::before {
        box-sizing: inherit;
      }

      &::after {
        box-sizing: inherit;
      }
    }
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
  }

  button, .button {
    --bg: rgba(0,0,0,0);
    background: var(--bg);
    color: var(--white);
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  /* Scrollbar Styles */
  *::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--white);
  }

  body::-webkit-scrollbar-track {
    background: var(--white);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

  .grey-bg {
    --bg: #F5F5F3;
    background-color: var(--bg);
  }
  
  .badge {
    color: var(--white);
    padding: .4rem 2rem;
    background-color: var(--bg);
    border-radius: 5rem;
  }
  
  .inline-block {
    display: inline-block;
  }

  .list-unstyled {
    list-style: none;
    padding-left: 0;
  }
  
  @counter-style diamond {
    system: cyclic;
    symbols: ✦;
    suffix: " ";
  }
`;

export default GlobalStyles;
