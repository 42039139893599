import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { NoStyleButton } from '../components/Button';
import Layout from '../components/Layout'
import { productList } from '../data';
import PageHead from '../templates/PageHead';
import Img from '../components/Img';

import img_btn from '../assets/img/202112/05-2.png';
import { Container } from '../styles/Grids';
import { breakPoint } from '../styles/var';

const GridStyles = styled.div`
  display: grid;
  gap: 2rem;
  padding-top: 3rem;
  padding-bottom: 10rem;
  @media (min-width: ${breakPoint.min}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const ItemStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .name {
      margin-bottom: 1rem;
      margin-top: 1rem;
  }
  .btn {
      display: block;
      width: 48%;
      margin-left: auto;
      margin-right: auto;
  }
`;

const List = ({match, ...props}) => {
    const {target} = match.params;
    if (!productList[target]) return false;
    const {products, img, lgImg, title} = productList[target];
    return (
      <Layout {...props}>
        <PageHead
          img={{
            sm: img,
            lg: lgImg,
          }}
        />
        <Container>
          <h1 className="page-title">{title}</h1>
          <GridStyles>
            {products.map((item) => (
              <ItemStyles key={item.img}>
                <Img src={item.img} auto />
                <h2 className="name">{item.title}</h2>
                <NoStyleButton as={Link} className="btn" to={`${match.url}/${item.to}`}>
                  <Img src={img_btn} auto />
                </NoStyleButton>
              </ItemStyles>
            ))}
          </GridStyles>
        </Container>
      </Layout>
    );
}

export default List
