export const breakPoint = {
  min: '768px',
  max: '767px',
  lgMin: '1440px',
  lgMax: '1439px',
  smMin: '430px',
  smMax: '429px',
};

export const headerBreakPoint = {
  min: '970px',
  max: '969px',
};
