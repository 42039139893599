import React from 'react';
import styled, {keyframes} from 'styled-components';

const loadingAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const LoadingStyled = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  .letter {
    animation: ${loadingAnimation} 0.48s infinite linear;
  }
  .s2 {
    animation-delay: .6s;
  }
  .s3 {
    animation-delay: .72s;
  }
`

const Loading = ({isShow, ...props}) => {
  if (!isShow) return false
  return (
    <LoadingStyled {...props}>loading
      <span className="s1 letter">.</span>
      <span className="s2 letter">.</span>
      <span className="s3 letter">.</span>
    </LoadingStyled>
  )
};

export default Loading;
