import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import PageHead from '../templates/PageHead';
import { Container, CardGridBaseStyle } from '../styles/Grids';
import Card from '../components/Card';
import { breakPoint } from '../styles/var';

import img_10_0 from '../assets/img/202112/PC10-0.png';
import img_10_0_s from '../assets/img/202112/10-0.png';
import img_10_1 from '../assets/img/202112/10-1.png';

const CardStyle = styled(CardGridBaseStyle)`
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: ${breakPoint.min}) {
    margin-bottom: 5rem;
    .content {
      font-size: 2rem;
      max-width: none;
      width: 80%;
    }
  }
`;

const ContactUs = () => {
    return (
      <Layout>
        <PageHead img={{ sm: img_10_0_s, lg: img_10_0 }} />
        <Container>
          <h1 className="page-title">聯絡我們</h1>
          <CardStyle>
            <Card imgUrl={img_10_1}>
              <h2 className="title">好萊兒嬰兒用品有限公司</h2>
              <ul className="list-unstyled">
                <li>電話 : 05-236-0889</li>
                <li>傳真 : 05-236-0855</li>
                <li>客服LINE ID: 052360889</li>
              </ul>
            </Card>
          </CardStyle>
        </Container>
      </Layout>
    );
}

export default ContactUs
