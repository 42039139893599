import React from 'react';
import Layout from '../components/Layout';


import PageHead from '../templates/PageHead';
import {Container} from '../styles/Grids';
import Img from '../components/Img';

import img_5_1 from '../assets/img/PC05-1.png';
import img_5_1_s from '../assets/img/05-1.png';
import img_5_2 from '../assets/img/PC05-2.png';
import img_5_3 from '../assets/img/PC05-3.png';
import img_5_4 from '../assets/img/PC05-4.png';
import img_5_5 from '../assets/img/PC05-5.png';
import img_5_2_s from '../assets/img/05-2.png';
import img_5_3_s from '../assets/img/05-3.png';
import img_5_4_s from '../assets/img/05-4.png';
import img_5_5_s from '../assets/img/05-5.png';
import styled from 'styled-components';
import {breakPoint} from '../styles/var';

const headImg = {
  lg: img_5_1,
  sm: img_5_1_s
}

const CategoryStyle = styled.div`
  > .title {
    border-bottom: 1px solid var(--font);
  }
`;

const GridStyle = styled.div`
  display: flex;
  gap: 5rem;
  margin-bottom: 5rem;
  @media (max-width: ${breakPoint.max}) {
    flex-wrap: wrap;
  } 
`;

const Category = ({title, imgUrl, smallUrl}) => {
  return (
    <CategoryStyle>
      <h2 className="title">{title}</h2>
      <Img src={imgUrl} wrapProps={{className: 'img', as: 'picture'}} auto>
        {
          smallUrl && <source media="(max-width:767px)" srcSet={smallUrl}/>
        }
      </Img>
    </CategoryStyle>
  )
}

const AwardWinning = (props) => {
  return (
    <Layout>
      <PageHead img={headImg}/>
      <Container>
        <h1 className="page-title">獲獎紀錄</h1>
        <p className="center">Britax Römer屢獲各式奬項及殊榮，<br/>
          以表彰一直以來的創新、堅持與努力</p>
        <GridStyle>
          <Category title="汽座DUALFIX i-SIZE" imgUrl={img_5_2} smallUrl={img_5_2_s}/>
          <Category title="汽座KIDFIX i-SIZE" imgUrl={img_5_3} smallUrl={img_5_3_s}/>
        </GridStyle>
        <GridStyle>
          <Category title="汽座ADVANSAFIX 系列" imgUrl={img_5_4} smallUrl={img_5_4_s}/>
          <Category title="推車SMILE III" imgUrl={img_5_5} smallUrl={img_5_5_s}/>
        </GridStyle>
      </Container>
    </Layout>
  )
};

export default AwardWinning;
