import React from 'react';
import styled from 'styled-components';
import OuterLink from './OuterLink';
import Img from './Img';
import {Link} from 'react-router-dom';
import {headerBreakPoint} from '../styles/var';


import img_1 from '../assets/img/PC00-2.png';
import img_2 from '../assets/img/PC00-3.png';
import img_3 from '../assets/img/PC00-4.png';


const LinkStyle = styled(OuterLink)`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  > .img {
    width: auto;
    margin-right: 1rem;
  }
  @media (max-width: ${headerBreakPoint.max}) {
    padding: 1rem 0 1rem 3rem;
  }
`;

const CusLink = (props) => (
  <>
    <LinkStyle href="https://www.facebook.com/Britax-Taiwan-758329691029610/">
      <Img src={img_1} wrapProps={{className: 'img'}} auto/>
      <span>粉絲專頁</span>
    </LinkStyle>
    <LinkStyle as={Link} to="/store">
      <Img src={img_2} wrapProps={{className: 'img'}} auto/>
      <span>經銷門市</span>
    </LinkStyle>
    <LinkStyle href="http://www.holybaby.com.tw/">
      <Img src={img_3} wrapProps={{className: 'img'}} auto/>
      <span>總代理</span>
    </LinkStyle>
  </>
);

export default CusLink;
