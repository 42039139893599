import React from 'react';
import styled from 'styled-components';
import {breakPoint} from '../styles/var';
import OuterLink from './OuterLink';
import Img from './Img';
import {footerMenu} from '../data';
import {Container} from '../styles/Grids';
import LinkGroup from './LinkGroup';

// images
import s_logo_1 from '../assets/img/PC00-6.png';
import icon_1 from '../assets/img/PC00-7.png';
import icon_2 from '../assets/img/PC00-8.png';
import icon_3 from '../assets/img/PC00-9.png';
import icon_4 from '../assets/img/PC00-10.png';

const icons = [
  {
    img: icon_1,
    href: 'https://www.facebook.com/Britax-Taiwan-758329691029610/',
    target: '_blank'
  },
  {
    img: icon_2,
    href: 'https://www.youtube.com/channel/UCZtQnrPHqEji_3wgOtOoUOg/featured',
    target: '_blank'
  },
  {
    img: icon_3,
    href: '/store',
    target: '_self'
  },
  {
    img: icon_4,
    href: 'http://www.holybaby.com.tw/',
    target: '_blank'
  },
]


const FooterStyles = styled.footer`
  --bg: var(--primary);
  font-size: 1.4rem;
  position: relative;
  color: var(--white);
  background-color: var(--bg);
  padding-top: 3rem;
  a {
    color: var(--white)
  }
  @media (max-width: ${breakPoint.smMax}) {
  }
`

const CopyrightStyles = styled.div`
  padding: 1.5rem 2rem;
  border-top: 1px solid var(--white);
  margin-top: 1.5rem;
`;

const FooterGridStyle = styled.div`
  display: flex;
  > div {
    width: 100%;
  }
  @media (min-width: ${breakPoint.min}) {
    .mb-links-wrap {
      display: none;
    }
    .logo {
      padding: 1rem;
    }
  }
  @media (max-width: ${breakPoint.max}) {
    flex-wrap: wrap;
    h3, p {
      font-size: 1.4rem;
    }
    .footer-link {
      display: none;
    }
    > div:not(.iconLinks) {
      flex-basis: 50%;
    }
    .logo {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 3rem;
    }
    .iconLinks {
      justify-content: center;
      margin-top: 1rem;
    }
  }
`;

const IconLinkStyle = styled.div`
  padding: .5rem 0;
  display: flex;
  gap: 1rem;
  .icon-link {
    width: auto;
  }
`;

const IconLinks = (props) => {
  return (
    <IconLinkStyle {...props}>
      {
        icons.map(({img, href, target}, i) => (
          <OuterLink key={i} href={href} target={target}>
            <Img src={img} wrapProps={{className: 'icon-link'}} auto />
          </OuterLink>
        ))
      }
    </IconLinkStyle>
  )
}

const Footer = () => {
  return (
    <FooterStyles>
      <Container className="container">
        <FooterGridStyle>
          <Img src={s_logo_1} wrapProps={{className: 'logo'}} auto/>
          {
            footerMenu.map((item,  i ) => (
              <LinkGroup key={i} {...item} className="footer-link"/>
            ))
          }
          <div className="mb-links-wrap">
            {
              footerMenu.map((item, i) => (
                <LinkGroup key={i} {...item}/>
              ))
            }
          </div>
          <IconLinks className="iconLinks"/>
        </FooterGridStyle>
      </Container>
      <CopyrightStyles className="center">
        Copyright© {new Date().getFullYear()} Britax 保留所有權利
      </CopyrightStyles>
    </FooterStyles>
  )
}

export default Footer;
