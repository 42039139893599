import React from 'react';
import {Link} from 'react-router-dom';
import Img from '../components/Img';

import img_search from '../assets/img/PC01-4.png';
import styled from 'styled-components';
import {breakPoint} from '../styles/var';

const PageHeadStyle = styled.div`
  position: relative;
  z-index: var(--level1);
  margin-bottom: 3rem;
  .link {
    position: absolute;
    top: calc(100% + 30px);
    right: 0;
  }
  .link-img {
    width: auto;
  }
  > .title {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    color: var(--white);
    text-shadow: 2px 2px 6px rgba(0,0,0,.8);
  }
  @media (max-width: ${breakPoint.max}) {
    .link {
      position: absolute;
      top: calc(100% + 20px);
      right: 0;
    }
    .link-img {
      max-width: 5rem;
    }
    .title {
      white-space: nowrap;
      font-size: 2.6rem;
    }
  }
`;

const PictureStyle = styled.picture`
  display: flex;
  justify-content: center;
`

const PageHead = ({img, hasLink = true, title}) => {
  return (
    <PageHeadStyle>
      <PictureStyle>
        <source media="(min-width:768px)" srcSet={img.lg}/>
        <img src={img.sm} alt="形象圖" />
      </PictureStyle>
      {
        hasLink && (
          <Link className='link' to="/store">
            <Img src={img_search} wrapProps={{className: 'link-img'}} auto/>
          </Link>
        )
      }
      {
        title && (
          <h1 className='title'>{title}</h1>
        )
      }
    </PageHeadStyle>
  )
};

export default PageHead;
