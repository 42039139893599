import styled from 'styled-components';
import {breakPoint} from './var';

const MenuStyles = styled.ul`
  list-style: none;
  display: flex;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  padding-left: 0;
  margin: 0;
  li {
    flex: 0 0 auto;
    width: auto;
  }
  p {
    margin: 0;
  }
  a {
    display: block;
    padding: 1rem;
  }
  @media (max-width: ${breakPoint.max}) {
    flex-wrap: wrap;
  }
`;

export default MenuStyles;
