import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import Routes from './Routes';




const App = (props) => {
  return (
    <BrowserRouter>
      {renderRoutes(Routes)}
    </BrowserRouter>
  )
};

export default App;
