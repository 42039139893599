import styled from 'styled-components';
import {breakPoint} from './var';


export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (min-width: ${breakPoint.lgMin}) {
    max-width: 120rem;
  }
`;

export const CardGridBaseStyle = styled.div`
  .card {
    > * {
      grid-row-start: 1;
    }
  }
  .card:nth-child(even) {
    .img {
      grid-column-start: 2;
    }
  }
`

export const CardGridStyle = styled(CardGridBaseStyle)`
  .card {
    grid-template-columns: 1fr 2fr;
    margin-bottom: 2rem;
    &:nth-child(even) {
      grid-template-columns: 2fr 1fr;
      .img {
        grid-column-start: 1;
      }
    }
    &:nth-child(odd) {
      .img {
        grid-column-start: 2;
      }
    }
    .content {
      text-align: center;
      *:not(.btn) {
        text-align: left;
      }
    }
  }
`;
