import React from 'react';
import Img from './Img';
import {CardStyles} from '../styles/Card';
import clsx from 'clsx';



const Card = ({imgUrl,smallImgUrl, children, className, ...props}) => {
  return (
    <CardStyles {...props} className={clsx('card', className)}>
      <Img src={imgUrl} wrapProps={{className: 'img', as: 'picture'}} auto>
        {
          smallImgUrl && <source media="(max-width:767px)" srcSet={smallImgUrl}/>
        }
      </Img>
      <div className="content">{children}</div>
    </CardStyles>
  )
};

export default Card;
