import {Component} from 'react';
import clsx from 'clsx';

const withHover = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isHover: false,
      }
    }
    handleMouseEvent = (change) => {
      this.setState({
        isHover: change
      })
    }
    render() {
      return (
        <WrappedComponent {...this.props} className={clsx(this.props.className, 'animate__animated', {'animate__heartBeat': this.state.isHover})} onMouseEnter={this.handleMouseEvent.bind(null, true)} onMouseLeave={this.handleMouseEvent.bind(null, false)}>
          {this.props.children}
        </WrappedComponent>
      )
    }
  }
}

export default withHover;
