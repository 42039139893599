import styled from 'styled-components';
import {breakPoint} from './var';


export const CardStyles = styled.div`
  .img {
    margin-bottom: 1rem;
    text-align: center;
  }
  .title {
    line-height: 1.3;
    white-space: pre-line;
  }
  .content {
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    padding: 2rem;
    > p {
      text-align: justify;
    }
  }
  @media (min-width: ${breakPoint.min}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 2rem;
  }
  @media (max-width: ${breakPoint.max}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

