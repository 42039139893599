import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import clsx from 'clsx';
import 'normalize.css';
import 'animate.css';
import {GlobalStyles, Typography} from '../styles';
import Loading from './Loading';
import Header from './Header';
import Footer from './Footer';

const ContentStyled = styled.div`
  
  .wrap {
    max-width: 192rem;
    margin-right: auto;
    margin-left: auto;
  }
  &.load {
    opacity: 0;
    height: 100vh;
    overflow: hidden;
  }
`;

const Layout = ({children, className, ...props}) => {
  const [contentClass, setContentClass] = useState('load');

  useEffect(() => {
    setTimeout(() => {
      setContentClass('');
    }, 1000);
  }, []);

  return (
    <>
      <GlobalStyles/>
      <Typography/>
      <ContentStyled className={clsx(contentClass, className)} {...props}>
        <Header />
        <div className="wrap">
          {children}
        </div>
        <Footer />
      </ContentStyled>
      <Loading isShow={contentClass === 'load'} />
    </>
  )
}

export default Layout;
