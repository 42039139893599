import React from 'react';
import Layout from '../components/Layout';
import PageHead from '../templates/PageHead';
import styled from 'styled-components';

import img_09_0 from '../assets/img/202112/PC09-0.png';
import img_09_0_s from '../assets/img/202112/09-0.png';
import { breakPoint } from '../styles/var';

const WrapStyles = styled.div`
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem 3rem;
  .update {
      margin-top: 4rem;
  }
  .title {
      margin-bottom: 1rem;
      + p {
        margin-top: 0;
          margin-bottom: 2.5rem;
      }
  }
  @media (min-width: ${breakPoint.min}) {
      padding-bottom: 5rem;
  }
  @media (max-width: ${breakPoint.max}) {
      .title {
          font-size: 2rem;
      }
  }
`;

const PrivacyPolicy = () => {
    return (
      <Layout>
        <PageHead
          img={{ sm: img_09_0_s, lg: img_09_0 }}
          title="好萊兒網站法律聲明"
        />
        <WrapStyles>
            <h2 className="title">法律聲明事項</h2>
            <p>歡迎您上網搜尋好萊兒網站的各項資訊，作為您參考的依據。關於好萊兒網站的所有內容，本公司享有文字、圖片的著作權法，禁止有關生產、複製、印刷、翻寫、再版、上傳、郵寄、…等等將網站資料外傳的行為，此外未經明確告知及書面許可，不得以任何形式複製、修改、展示及傳送好萊兒及其所有代理商品的名稱、商標、影像、正文等內容。 凡是有以上所述之資料不正當利用行為，即已觸犯本公司之版權及所有權，本公司將依法追究。</p>
            <h2 className="title">網頁連結事項</h2>
            <p>對於以第三方的連結方式與好萊兒相連之網頁內容，好萊兒一概不承擔責任，我們將追究該連結內容是由第三方提供，或是由好萊兒所提供的。當您通過連結而進入好萊兒的網頁時，請注意此網頁是獨立的，好萊兒對於其內容無法控制。此外，好萊兒提供網頁連結，並不表示好萊兒贊同與其相連網頁之內容，也不意味願意為其負擔責任。</p>
            <h2 className="title">免責聲明事項</h2>
            <p>好萊兒不保證網頁及服務器上的內容不受病毒感染，若因上述原因而造成您的損失，您需自行承擔有關服務、維修及修正等費用，包括在疏忽的情形下，好萊兒不對於由連接、瀏覽、使用好萊兒網頁，以及和好萊兒網頁連結的網頁，所造成任何形式的損失承擔責任，即使好萊兒及其代表已經被告知可能會有損失發生，好萊兒仍不承擔責任。<br/>如果您對說明有任何疑問或意見，請聯絡好萊兒05-2360889</p>
            <h2 className="title">隱私權聲明</h2>
            <p>好萊兒公司尊重您的隱私權。好萊兒謹藉由此隱私權保護聲明，讓您清楚了解好萊兒如何蒐集、應用、處理及保護您在本網站上所提供的個人基本資料。除非您自願提供，否則本公司不會藉由此網站來蒐集您的個人基本資料。當您於網頁中填寫您個人基本資料時，即表示您同意本公司可根據此隱私權保護聲明所載明的方式，來進行您個人基本資料的收集、儲存與運用。您瀏覽及使用本網頁行為視為您同意本聲明內容。</p>
            <h2 className="title">個人基本資料的蒐集</h2>
            <p>在本隱私權保護聲明中，「個人基本資料」係指可辨認個人身分的資料，例如：姓名、地址、電話號碼、電子信箱、生日及其他可連絡或辨識您身分的資料。 好萊兒蒐集上述資料的目的是為了能提供您更迅速及優質的資訊與服務。當您於本網站訂閱電子報、使用相關服務或進行活動參與時，本網站可能會要求您留存您的真實個人基本資料，以便於和您聯繫、提供服務或處理訂閱程序；在此情況下，好萊兒會事先告知您資料蒐集的目的和運用方式，並提供您可選擇限定資料和終止提供的方式。此注意事項會以清楚明白的語言顯示，您可以自由選擇提供您的個人基本資料與否。 您使用本網站時，我們的伺服器會自動記錄相關記錄，例如您的IP位址、使用的瀏覽器及操作記錄等。關於您和其他使用者的瀏覽模式，好萊兒可能將之彙整作為個人用途之研究資料。</p>
            <h2 className="title">個人基本資料的使用</h2>
            <p>好萊兒會長期儲存並維護您自願提供給好萊兒的個人基本資料，用以提供您所需要的服務，直到您直接向好萊兒表達終止保留資料或法律要求好萊兒終止保留資料為止。 好萊兒將運用您的個人基本資料以維繫您與好萊兒間的連絡關係，例如傳送商業參考資訊、電子報或電子郵件。未經您事先同意，好萊兒絕不會將您的個人資料揭露、販售或給予其他不相干機構從事行銷或兜售行為；但好萊兒保留將您的個人基本資料在提供上述服務之範圍內提供給好萊兒的關係企業、代理商或承包商等的權利。 如果依法律或因收到法院或政府命令、搜索票、傳票，或配合警方辦案必須提供您的個人基本資料，或是為了保護好萊兒及其關係企業的權益時，好萊兒也會依法提供相關資料。</p>
            <h2 className="title">Cookie功能運用</h2>
            <p>Cookies 是伺服端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些簡短資訊。本網站為保障使用者，於程式中並未加入 Cookie 的使用以避免探得您不被告知之隱私。當然您也可以修改您的瀏覽器對Cookies的接受程度以保障自己的權益。</p>
            <h2 className="title">修改或刪除個人基本資料</h2>
            <p>如果您於訂閱電子報後欲修改您的個人基本資料，或擬不再繼續收受任何好萊兒寄送給您的資訊，請您於訂閱電子報處修改或勾選取消訂閱，服務人員於收到您的通知後將儘速處理。</p>
            <h2 className="title">注意事項</h2>
            <p>此隱私權保護聲明僅適用於好萊兒透過本網站所蒐集到的使用者個人基本資料。藉由廣告或其他超連結連至好萊兒網站而得的資料，並不列於此隱私權保護聲明中。好萊兒建議您連結至其他網站時，在自願提供個人基本資料前，詳讀該網站的隱私權保護聲明。</p>
            <h2 className="title">變更聲明</h2>
            <p>好萊兒保留不定期修訂此份隱私權保護聲明之權利。好萊兒有權在未事先告知的情況下變動此聲明。好萊兒將於隱私權保護聲明網頁中敘明最後一次更新的日期，並建議您無論何時瀏覽本網站，都先檢視隱私權保護聲明網頁，以得知變動事項。</p>
            <p className="update">最後一次更新時間： 西元 2020年1月18日</p>
        </WrapStyles>
      </Layout>
    );
}

export default PrivacyPolicy
